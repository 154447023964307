import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Date } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  addDynamicForm as onaddDynamicForm,
  updateDynamicForm as onUpdateDynamicForm,
  deleteDynamicForm as onDeleteDynamicForm,
  getDynamicForm as onGetDynamicForm ,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import moment from "moment";
import TableContainer from "../../../components/Common/TableContainer";

import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {

  //meta title
  document.title = "Pdr Testleri | EfsoKoç ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEdit, setEdit] = useState();

  
  // validationf
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (getEdit && getEdit.Dynamic_Form_Name) || "",
      startAge: (getEdit && getEdit.Dynamic_Form_StartAge) || "",
      finishAge: (getEdit && getEdit.Dynamic_Form_FinishAge) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Lütfen Adınızı Giriniz"),
      startAge: Yup.string().required("Başlangıç Yaşınızı Giriniz"),
      finishAge: Yup.string().required("Bitiş Yaşınızı Giriniz")
    }),
    onSubmit: values => {
      var localDate = moment().local();

      if (isEdit) {
        const updateUser = {
          ID: getEdit.ID,
          Dynamic_Form_Name:values.name,
          Dynamic_Form_StartAge: values.startAge,
          Dynamic_Form_FinishAge: values.finishAge,
          Dynamic_Form_IsActive: getActive
                };
                console.log("updateUser",updateUser)
        // update user
        dispatch(onUpdateDynamicForm(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Dynamic_Form_Name:values["name"],
          Dynamic_Form_StartAge: values["startAge"],
          Dynamic_Form_FinishAge: values["finishAge"],
          Dynamic_Form_IsActive: getActive,
          Dynamic_Form_CreatedDateTime: localDate
        };
        // save new user
        dispatch(onaddDynamicForm(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.dynamic,
  }));
  

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getActive, setActive] = useState(false);


  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
     
      {
        Header: 'Kategori Adı',
        accessor: 'PdrCategory.PdrCategory_Title',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },


      {
        Header: 'Kullanıcı Adı',
        accessor: 'Users.Users_NameSurname',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        Header: 'Yanlış Sayısı',
        accessor: 'Users_Complate_Question_False',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },

      {
        Header: 'Doğru Sayısı',
        accessor: 'Users_Complate_Question_True',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
     
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {

      dispatch(onGetDynamicForm());

    }
  }, [dispatch, users]);




  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setContact(user);
    setIsEdit(true);

    setActive(user.Dynamic_Form_IsActive)

    setEdit(user)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteDynamicForm(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };




  const { reservation } = useSelector(state => ({
    reservation: state.contacts.reservation,
  }));

  const [getExcelModal, setExcelModal] = useState(false)

  const [getSelectDateFinish, setSelectDateFinish] = useState(null);
  const [getSelectDate, setSelectDate] = useState(null);

  const onOpen = (order) => {
    
    setExcelModal(true);
  }

  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }

    const filterFunc = (text) => {

    }

    
    const [count, setCount] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setCount(prevCount => {
          if (prevCount < 100) {
            return prevCount + 1;
          } else {
            clearInterval(interval);
            return prevCount;
          }
        });
      }, 100); // Zaman aralığını 100ms olarak ayarladık, bu daha yavaş bir animasyon sağlar.
  
      return () => clearInterval(interval);
    }, []);

  return (
    <React.Fragment>
    <DeleteModal
      show={deleteModal}
      onDeleteClick={handleDeleteUser}
      onCloseClick={() => setDeleteModal(false)}
    />
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Yönetim" breadcrumbItem="Cevaplanan Pdr Testler" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={users}
                  isGlobalFilter={true}
                  isAddAuthList={false}
                  handleUserClick={handleUserClicks}
                  customPageSize={10}
                  className="custom-header-css"
                />

      
                
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle} tag="h4">
                    {!!isEdit ? "Düzenle" : "Üye Ekle"}
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col xs={12}>

                          <div className="mb-3">
                            <Label className="form-label">Rol Adı</Label>
                            <Input
                              name="authName"
                              label="Rol Adı"
                              type="text"
                              placeholder="Rol Adını Giriniz"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.authName || ""}
                              invalid={
                                validation.touched.authName &&
                                  validation.errors.authName
                                  ? true
                                  : false
                              }
                            />
                             {validation.touched.authName &&
                              validation.errors.authName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.authName}
                              </FormFeedback>
                            ) : null}
                          </div>
                       
                        </Col>
                      </Row>
                      <Row>

                
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Kaydet
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
  );
};

function AnimatedNumber({ value }) {
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (displayValue < value) {
        setDisplayValue(prevDisplayValue => prevDisplayValue + 1);
      }
    }, 20); // Artış hızını 20ms olarak ayarladık, bu daha hızlı bir animasyon sağlar.

    return () => clearInterval(interval);
  }, [value, displayValue]);

  return  <h4 className="mb-0">{displayValue}</h4>
}


export default withRouter(ContactsList);
