import React, { useEffect, useState, useRef } from "react";
import withRouter from "components/Common/withRouter";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js';

// Registering components for Chart.js
ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale);

import {
  getProductCategory as ongetProductCategory,
  addProductCategory as onAddProductCategory,
  updateProductCategory as onUpdateProductCategory,
  deleteProductCategory as onDeleteProductCategory,
} from "store/contacts/actions";


import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const ContactsList = props => {
 
  //meta title
  document.title = "Soru Kategorisi | EfsoKoç Admin PANELI";
  const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];
  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const currentMonth = months[new Date().getMonth()]; // Mevcut ay
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [hedefInput, setHedefInput] = useState({});
  const [hedefInputsTargetReal, setHedefInputsTargetReal] = useState({});


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Question_Category_Title) || "",
      sort: (contactEdit && contactEdit.Sort) || ""


    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),

    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Question_Category_Title: values.title,
          Sort: values.sort,
          IsActive:getIsActive
        };
        dispatch(onUpdateProductCategory(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Question_Category_Title: values["title"],
          Sort: values["sort"],
          IsActive:getIsActive
        };
        // save new user
        dispatch(onAddProductCategory(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.productCategory,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [getIsActive, setIsActive] = useState(false);

  const [allLessons, setLesson] = useState([]);
  const [allTarget, setTarget] = useState([]);
  const [allTargetReal, setTargetReal] = useState([]);

  const fetchDersler = async () => {
    try {

      await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getLessonEfso/select/`)
      .then((res) => {
        setLesson(res.data)
      })

     
    } catch (error) {
      console.log("fetchDersler error:", error);
    }
  };

  useEffect(() => {
   
    fetchDersler();
    
 
}, [allLessons]);



  const fetchTarget = async () => {
    try {
      let authUser = JSON.parse(localStorage.getItem("authUser"));

      await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getTargetUserEfso/select/${authUser.ID}`)
      .then((res) => {
        setTarget(res.data)
      })
     
    } catch (error) {
      console.log("22fetchTarget error:", error);
    }
  };

  useEffect(() => {
   
    fetchTarget()
    
 
}, [allTarget]);


  const fetchTargetReal = async () => {
    try {
      let authUser = JSON.parse(localStorage.getItem("authUser"));

      await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getTargetRealUserEfso/select/${authUser.ID}`)
      .then((res) => {
        setTargetReal(res.data)
      })
    
    } catch (error) {
      console.log("33fetchTarget error:", error);
    }
  };

  useEffect(() => {
   
      fetchTargetReal()
      
   
  }, [allTargetReal]);


  useEffect(() => {
    const currentMonth = moment().format('MMMM');
    fetchTargetPicker(currentMonth);
  }, []);


  const toggle = () => {
    setModal(!modal);
  };


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

 
  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const targets = Object.keys(hedefInput).map(lessonId => ({
        Lesson_ID: parseInt(lessonId),
        TargetNumber: parseInt(hedefInput[lessonId]),
      }));

      const targetsReal = Object.keys(hedefInputsTargetReal).map(lessonId => ({
        Lesson_ID: parseInt(lessonId),
        TargetReal_True: parseInt(hedefInputsTargetReal[lessonId]),
      }));

      await handleTargets(targets, targetsReal);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  function showToast(type,message,type2) {

    let ele = null
  
     if(type2 == true){
       ele = "success"
       message = message
    } else{
       ele = "error"
       message = message 
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const handleTargets = async (targets, targetsReal) => {
    try {

      const testDateUtc = moment.utc();
      const localDate = moment(testDateUtc).local();

      let authUser = JSON.parse(localStorage.getItem("authUser"));
      
      
      

      for (const target of targets) {
        const response = await axios.post('https://sihaadmin.stechomeyazilim.info:8182/addTargetEfso/send', {
          Lesson_ID: target.Lesson_ID,
          Users_ID: authUser.ID,
          TargetNumber: target.TargetNumber,
          SelectMonth: selectedMonth,
          Created_DateTime: localDate
        });

        console.log("şömsdşlf",response.data)
        if (response.data.value.length > 0) {

          
          for (const target1 of targetsReal) {
            parseInt(target1.TargetReal_True)

            await axios.post('https://sihaadmin.stechomeyazilim.info:8182/addTargetRealEfso/send', {
              TargetReal_True: parseInt(target1.TargetReal_True),
              Lesson_ID: target1.Lesson_ID,
              SelectMonth: selectedMonth,
              Created_DateTime: localDate,
              Users_ID: authUser.ID
            });
          }

          // Re-fetch data if needed
          // fetchDersler();
          // fetchTarget();
          // fetchTargetReal();

          const currentMonth = getCurrentMonth(); // Örneğin, 'Temmuz' gibi bir değer döndüren bir fonksiyon
          setSelectedMonth(currentMonth);
          showToast('Efso Dijital', "Veriler başarıyla işlendi!", true)
          
        } else {
          showToast('Efso Dijital', "Veriler işlenirken bir sorun oluştu!", false)
        }
      }
    } catch (error) {
      console.error('Error handling targets:', error);
    
    }          showToast('Efso Dijital', "Veriler işlenirken bir sorun oluştu!", false)

  };

  const getCurrentMonth = () => {
    // Örnek: Temmuz
    //fetchTargetPicker
    return moment().format('MMMM');
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteProductCategory(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  // Filtreleme işlemi
  const filteredTargets = selectedMonth != null && allTarget.length > 0 
    ? allTarget.filter(target => target.SelectMonth === selectedMonth) 
    : [];

  const filteredTargetsReal = selectedMonth != null && allTargetReal.length > 0 
    ? allTargetReal.filter(target => target.SelectMonth === selectedMonth) 
    : [];

  // Hedef input değişikliği
  const handleInputChange = (value, lessonId) => {
    setHedefInput({ ...hedefInput, [lessonId]: value });
  };

  // Gerçek net input değişikliği
  const handleInputChangeReal = (value, lessonId) => {
    setHedefInputsTargetReal({ ...hedefInputsTargetReal, [lessonId]: value });
  };

  // Progress hesaplama (hedef ile gerçek arasındaki farkı gösterir)
  const calculateProgress = (lessonId) => {
    const hedef = parseInt(hedefInput[lessonId]) || 0;
    const gerçek = parseInt(hedefInputsTargetReal[lessonId]) || 0;
    if (hedef === 0) return 0;
    return (gerçek / hedef) * 100;
  };

  const fetchTargetPicker = async (selectedMonth) => {
    try {

      setSelectedMonth(selectedMonth)

      selectedMonth = selectedMonth || selectedMonth;
      let authUser = JSON.parse(localStorage.getItem("authUser"));

      
      const hedefInputs = {};
      const hedefInputsTargetReal = {};

await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getTargetMonthUserEfso/select/${selectedMonth}/${authUser.ID}`)
.then((rsp) => {
  console.log("şsmdfş22l",rsp.data)

  rsp.data.forEach(target => {
    hedefInputs[target.Lesson_ID] = target.TargetNumber.toString();
  });

  setHedefInput(hedefInputs)

})


await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getTargetRealMonthUserEfso/select/${selectedMonth}/${authUser.ID}`)
.then((rspReal) => {

  rspReal.data.forEach(target => {

    hedefInputsTargetReal[target.Lesson_ID] = target.TargetReal_True.toString();
  })
  setHedefInputsTargetReal(hedefInputsTargetReal)

})

    
    

    } catch (error) {
      console.log("fetchTargetPicker error:", error);
    }
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Soru Kategorisi Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


           

      {/* Ay seçme menüsü */}
      <select  style={{
        margin:'10px',
            padding: '10px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: '#fff',
            cursor: 'pointer'
          }} value={selectedMonth} onChange={(e) => fetchTargetPicker(e.target.value)}>
        {months.map(month => (
          <option key={month} value={month}>{month}</option>
        ))}
      </select>

      {allLessons.map(ders => {
        // Filtrelenmiş hedef ve gerçek data
        const lessonFilteredTargets = filteredTargets.filter(target => target.Lesson_ID === ders.ID);
        const lessonFilteredTargetsReal = filteredTargetsReal.filter(target => target.Lesson_ID === ders.ID);

        const lessonChartData = {
          labels: lessonFilteredTargets.map(target => target.SelectMonth),
          datasets: [
            {
              label: 'Hedef',
              data: lessonFilteredTargets.map(target => target.TargetNumber),
              borderColor: 'rgba(0, 0, 255, 0.7)',
              backgroundColor: 'rgba(0, 0, 255, 0.1)',
              borderWidth: 2,
              fill: false,
            },
            {
              label: 'Gerçek Sonuç',
              data: lessonFilteredTargetsReal.map(target => target.TargetReal_True),
              borderColor: 'rgba(255, 0, 0, 0.7)',
              backgroundColor: 'rgba(255, 0, 0, 0.1)',
              borderWidth: 2,
              fill: false,
            },
          ],
        };

        const options = {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `${context.dataset.label}: ${context.raw}`;
                },
              },
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Aylar',
              },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Değer',
              },
            },
          },
        };
        

        // Progress hesaplama
        const progress = calculateProgress(ders.ID);

      //  console.log("lknsdlkf",hedefInput[ders.ID])
        return (
          <div key={ders.ID} style={{ marginBottom: '20px' }}>
            <h2>{ders.Lesson_Title}</h2>

            {lessonChartData.labels.length > 0 && (
      <Line height={150} data={lessonChartData} options={options} />
    )}
              
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
              <div>
                <label>Net Hedefiniz</label>
                <input
                  type="number"
                  value={hedefInput[ders.ID] || ''}
                  onChange={(e) => handleInputChange(e.target.value, ders.ID)}
                  placeholder="Net Hedefinizi Girin"
                />
              </div>

              <div>
                <label style={{padding:5}}>Yaptığınız Net Sayısı</label>
                <input
                
                  type="number"
                  value={hedefInputsTargetReal[ders.ID] || ''}
                  onChange={(e) => handleInputChangeReal(e.target.value, ders.ID)}
                  placeholder="Yaptığınız Neti Girin"
                />
              </div>
            </div>

            {lessonChartData.labels.length > 0 && selectedMonth !== null && progress !== 0 && (
              <p style={{ color: progress > 0 ? 'green' : 'red' }}>
                Hedefin {hedefInput[ders.ID]} Doğru - Başarı Yüzden {progress.toFixed(0)}%
              </p>
            )}

<button  style={{
          padding: '10px 20px',
          fontSize: '16px',
          color: '#fff',
          backgroundColor: '#007bff',
          border: 'none',
          borderRadius: '4px',
          cursor: isLoading ? 'not-allowed' : 'pointer',
          marginTop: '10px'
        }} onClick={handleSubmit} disabled={isLoading}>
        {isLoading ? 'Yükleniyor...' : 'Kaydet'}
      </button>


          </div>
        );
      })}
  
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kategori Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Soru Kategori Başlığını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Sıralama</Label>
                              <Input
                                name="sort"
                                label="sort"
                                type="number"
                                placeholder="Sıralama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sort || ""}
                                invalid={
                                  validation.touched.sort &&
                                    validation.errors.sort
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.sort &&
                                validation.errors.sort ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sort}
                                </FormFeedback>
                              ) : null}
                            </div>

                         
                            <div className="d-flex">
                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                         
                        </div>



                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
