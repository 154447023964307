import React, { useEffect, useState, useRef, useMemo } from "react";
import withRouter from "components/Common/withRouter";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";
import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css'; // Takvim için gerekli CSS dosyası
import './EventCard.css'; // Takvim için gerekli CSS dosyası
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  getProductSubSubCategory as onGetProductSubSubCategory,
  addProductSubSubCategory as onAddProductSubSubCategory,
  updateSubSubProductCategory as onUpdateSubSubProductCategory,
  deleteProductSubSubCategory as onDeleteProductSubSubCategory,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const ContactsList = props => {

  //meta title
  document.title = "Koç Görüşmesi | EfsoKoç Dijital Kullanıcı PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getSubCategoryID, setSubCategoryID] = useState();
  const [getIsActive, setIsActive] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [getQuestionText, setQuestionText] = useState(null);
  const [getCoachID, setCoachID] = useState(null);

  const [getCalendar, setCalendar] = useState(null);

  
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.LessonSubject_Title) || "",
      desc: (contactEdit && contactEdit.LessonSubject_Desc) || "",
      sort:(contactEdit && contactEdit.Sort) || ""

    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),

    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          LessonSubject_Title: values.title,
          LessonSubject_Desc:values.desc,
          Sort:values.sort,
          IsActive:getIsActive,
          Lesson_ID : getSubCategoryID
        };
        dispatch(onUpdateSubSubProductCategory(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          LessonSubject_Title: values["title"],
          LessonSubject_Desc:values["desc"],
          Sort:values["sort"],
          IsActive:getIsActive,
          Lesson_ID : getSubCategoryID
        };
        // save new user
        dispatch(onAddProductSubSubCategory(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.productSubSubCategory,
  }));

  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [getFilterCheck, setFilterCheck] = useState(false);

  const [getCategory, setCategory] = useState([]);

  const [getCoach, setCoach] = useState([]);

  const [getPlan, setPlan] = useState([]);
  const [markedDates, setMarkedDates] = useState([]);



  const _getAllMeeting = async () => {
    try {

      let authUser = JSON.parse(localStorage.getItem("authUser"));
      if (authUser != null) {
      await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getMeetingUserEfso1/select/${authUser.ID}`)
      .then((rsp) => {

        const dates = [];
        setPlan(rsp.data)

        rsp.data.forEach(event => {
          const startDate = moment(event.Meeting_DateTime).format('YYYY-MM-DD');
          dates.push(startDate); // Tarihi diziye ekle
        });
        setMarkedDates(dates);
        
      })
      
    }
     

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    _getAllMeeting()
  }, [dispatch, users]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // API endpoint URL
      const apiUrl = 'https://sihaadmin.stechomeyazilim.info:8182/addMeetingEfso/send'; // API URL'inizi buraya koyun

      let authUser = JSON.parse(localStorage.getItem("authUser"));

      const testDateUtc = moment.utc();
      const localDate = moment(testDateUtc).local();

      const response = await axios.post(apiUrl, {
        Teacher_ID:getCoachID,
        Users_ID: authUser.ID,
        Created_DateTime: localDate,
        Meeting_DateTime:getCalendar,
        Meeting_Note:getQuestionText,
        
               })

      if (response.status === 200) {
        showToast('Efso Dijital', "Veriler başarıyla işlendi!", true)
        setModal(!modal);
      
     

          setTimeout(() =>  {
            _getAllMeeting()
          })
      } else {

        showToast('Efso Dijital', "Veriler işlenirken bir hata oluştu!", false)

      }
    } catch (error) {
      console.error('Error submitting data:', error);
      showToast('Efso Dijital', "Veriler işlenirken bir hata oluştu!", false)
    }
  };

  function showToast(type,message,type2) {

    let ele = null
  
     if(type2 == true){
       ele = "success"
       message = message
    } else{
       ele = "error"
       message = message 
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const _getAuthData = async () => {
    try {
    let authUser = JSON.parse(localStorage.getItem("authUser"));

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getTeacherOfUserEfso/select/${authUser.ID}`)
      .then((res) => {
        setCoach(res.data)
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    _getAuthData()
  
  }, [dispatch, getCategory]);


  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);


    setIsEdit(true);

    setSubCategoryID(user.Lesson_ID)
    setIsActive(user.IsActive)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteProductSubSubCategory(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };


  const _handleDelete = async(ID) => {
    try {

      axios.delete(`https://sihaadmin.stechomeyazilim.info:8182/deleteMeetingEfso/${ID}`).then(async(res) => {
        _getAllMeeting()
      })
      

    } catch (error) {
      console.error(error);
    }

  }


  const handleDateClick = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setSelectedDate(formattedDate);

    setFilterCheck(true)

    const filtered = getPlan.filter(event => moment(event.Meeting_DateTime).format('YYYY-MM-DD') === formattedDate);
    setFilteredData(filtered);
  };


  const renderWeeklySchedule = () => {
    const daysOfWeek = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];

    const weekStart = moment().startOf('week') // Pazartesi'den başlar
    const weekSchedule = daysOfWeek.map((day, index) => {
      const currentDay = weekStart.clone().add(index, 'days').format('YYYY-MM-DD');
      const events = getPlan.filter(event => event.Meeting_DateTime.split('T')[0] === currentDay);

      return (
      
        events.length > 0 ? 
        events.map((item, index) => (
          
          <div key={item.ID} className="card-container">

<Button
                type="button"
                color="danger"
                className="btn-rounded "
                style={{position:'absolute',right:0,top:0}}
                onClick={()=> _handleDelete(item.ID)}
              >
                <i className="mdi mdi-delete " />
              
              </Button>

            <p className="created-date">
           { moment(item.Created_DateTime).format('DD/MM/YYYY')} 
            </p>

            <div className="event-info-row">
              <div>
                <div className="info-item">
                  <p className="event-label">Görüşme Tarihi</p>
                </div>
                <div className="info-item">
                  <p className="event-label">Gün</p>
                </div>
                <div className="info-item">
                  <p className="event-label">Saat</p>
                </div>
                
                <div className="info-item">
                  <p className="event-label">Not</p>
                </div>

                <div className="info-item">
                  <p className="event-label">Koç</p>
                </div>
             
              </div>

              <div>
                <div className="info-item">
                  <p className="event-info">{currentDay}</p>
                </div>
                <div className="info-item">
                  <p className="event-info">{day}</p>
                </div>
                <div className="info-item">
                  <p className="event-info">
                  {item.Meeting_DateTime.split('T')[1].slice(0, 5)} - {item.Meeting_DateTime.split('T')[1].slice(0, 5)}
                  </p>
                </div>
             
                  <div className="info-item">
                    <p className="event-info"> {`${item.Meeting_Note}`}</p>
                  </div> 
     
                  <div className="info-item">
                    <p className="event-info"> {`${item.Teacher.Teacher_NameSurname}`}</p>
                  </div> 
     

              </div>
            </div>

      
         
        </div>
        ))
        : null
      )
    });

    return weekSchedule; 
  };

  const handleUserReset = (arg) => {
    setFilterCheck(false)
  };



  const handleCoach = async(e) => {
    const selectedID = e.target.value;
    setCoachID(selectedID);

  };


  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = moment(date).format('YYYY-MM-DD');
   
      return markedDates.includes(formattedDate) ? 'highlighted' : null
    }
    return null;
  };

  const handleInput = (e) => {
    setQuestionText(e.target.value);
  };

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCalendar(value); 
  };
  

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Ödev Listesi" />

          <Row>

        

            <Col lg="12">
              <Card>
                <CardBody>

                <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus me-1" />
                Görüşme Ekle
              </Button>
            </div>


             
      <div className="calendar-container">
        <Calendar
          onChange={setSelectedDate}
          value={selectedDate}
          tileClassName={tileClassName}
          onClickDay={handleDateClick}
          readOnly
                  />
        </div>
   
        { getFilterCheck == true ?  <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleUserReset}
              >
                <i className="mdi mdi-refresh me-1" />
                Haftalık Planlamayı Göster
              </Button> : 
             null}

       { getFilterCheck == true ? 
        filteredData.map((item, index) => (
          
          <div key={item.ID} className="card-container">

<Button
                type="button"
                color="danger"
                className="btn-rounded "
                style={{position:'absolute',right:0,top:0}}
                onClick={()=> _handleDelete(item.ID)}
              >
                <i className="mdi mdi-delete " />
              
              </Button>

            <p className="created-date">
           {moment(item.Created_DateTime).format('DD/MM/YYYY')} 
            </p>

            <div className="event-info-row">
              <div>
                <div className="info-item">
                  <p className="event-label">Görüşme Tarihi</p>
                </div>
                <div className="info-item">
                  <p className="event-label">Gün</p>
                </div>
                <div className="info-item">
                  <p className="event-label">Saat</p>
                </div>
                
                <div className="info-item">
                  <p className="event-label">Not</p>
                </div>

                <div className="info-item">
                  <p className="event-label">Koç</p>
                </div>
             
              </div>

              <div>
                <div className="info-item">
                  <p className="event-info">  {moment(item.Meeting_DateTime).format('DD/MM/YYYY')} </p>
                </div>
                <div className="info-item">
                  <p className="event-info">  {moment(item.Meeting_DateTime).format('dddd')} </p>
                </div>
                <div className="info-item">
                  <p className="event-info">
                  {item.Meeting_DateTime.split('T')[1].slice(0, 5)} - {item.Meeting_DateTime.split('T')[1].slice(0, 5)}
                  </p>
                </div>
             
                  <div className="info-item">
                    <p className="event-info"> {`${item.Meeting_Note}`}</p>
                  </div> 
     
                  <div className="info-item">
                    <p className="event-info"> {`${item.Teacher.Teacher_NameSurname}`}</p>
                  </div> 
     

              </div>
            </div>

      
         
        </div>
        )):

         <div>{renderWeeklySchedule()}</div>}


         {/*
         
         selectedDate != null ? (
                  <div >
                    <div >
                      <p>Seçili Gün:</p>
                   
                    </div>

                    <div>{renderEventSlots()}</div>
                    
                  </div>
                ) : (
                  <div>
                    <div >
                      <p>Lütfen Gün Seçiniz</p>
                    </div>
                  </div>
                ) */}
                
  

                 {/*
                 renderEventSlots()
                 <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddProductCategory={false}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  /> */} 

        

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Planlama Ekle"}
                    </ModalHeader>
                    <ModalBody>
                        <form onSubmit={handleSubmit}>
                        <Row>
                          <Col xs={12}>
                      
                          <div className="mb-3">
                            <Label className="form-label">Koç Seçiniz</Label>
                              <Input
                                name="getCategoryID"
                                type="select"
                                className="form-select"
                                defaultValue={getCoachID}
                                onChange={handleCoach}
                                value={getCoachID}>
                                 <option value="">Lütfen Seçim Yapınız</option>

                                {getCoach.map((item, index) => (
                                  <option value={item.Teacher_ID} key={item.ID}>{item.Users.Users_NameSurname}</option>
                                ))}

                              </Input>
                              </div>

                              <div className="mb-3">
                              <Label className="form-label">Not Yazınız(opsiyonel)</Label>
                              <Input
                                name="question"
                                label="question"
                                type="text"
                                placeholder="Notunuzu Giriniz"
                                value={getQuestionText}
                                onChange={handleInput}
                               
                              />
                              
                           
                             
                  </div>

                            <div className="mb-3">
            <Label className="form-label">Tarih</Label>
            <Input
              type="date"
              name="date"
              value={getCalendar}
              onChange={handleInputChange}
            />
          </div>


                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
