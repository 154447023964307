import React, { useEffect, useState, useRef, useMemo } from "react";
import withRouter from "components/Common/withRouter";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Button,
} from "reactstrap";

import { Name, Email } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import "jspdf-autotable";
import * as XLSX from 'xlsx';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  getBook as onGetBook,
  addBook as onAddBook,
  updateBook as onUpdateBook,
  deleteBook as onDeleteBook,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";

const ContactsList = props => {

  //meta title
  document.title = "Kitap Listesi | EfsoKoç ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();



  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);


  const { users } = useSelector(state => ({
    users: state.contacts.book,
  }));

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);


  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }


  const _getAuthData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getLessonEfso/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()
  
  }, [dispatch, getCategory]);



  useEffect(() => {
    if (users && !users.length) {
      let authUser = JSON.parse(localStorage.getItem("authUser"));

      if (authUser.Users_Type_ID == 2) {
      dispatch(onGetBook(authUser.ID));
      setIsEdit(false);
      }else{
        dispatch(onGetBook());
        setIsEdit(false);
        
      }

    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const [selectedLesson, setSelectedLesson] = useState(getCategoryID);
  const [inputValues, setInputValues] = useState({
    netSayisi: '',
    yanlisSayisi: '',
    hedefNetSayisi: '',
    denemeAdi: '',
    tarih: '' // Tarih için bir state ekliyoruz

  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("msdnflk",inputValues)
    try {
      // API endpoint URL
      const apiUrl = 'https://sihaadmin.stechomeyazilim.info:8182/addTestEfso/send'; // API URL'inizi buraya koyun

      let authUser = JSON.parse(localStorage.getItem("authUser"));

      const testDateUtc = moment.utc();
      const localDate = moment(testDateUtc).local();


      const response = await axios.post(apiUrl, {
        Lesson_ID: selectedLesson,
        	Users_ID:authUser.ID,
          Created_DateTime:localDate,
          Test_Title:inputValues.denemeAdi,
          Test_CorrectAnswer:inputValues.netSayisi,
          Test_FalseAnswer:inputValues.yanlisSayisi,
          Test_DateTime	:inputValues.tarih,
          Target_CorrectAnswer:inputValues.hedefNetSayisi		
      
      });

      if (response.status === 200) {
        showToast(true,'Efso Dijital', "Veriler başarıyla işlendi!")
        setModal(!modal);
      
        
       
          setTimeout(() =>  {
            if (authUser.Users_Type_ID == 2) {
            dispatch(onGetBook(authUser.ID));
            setIsEdit(false);
            }else{
              dispatch(onGetBook());
              setIsEdit(false);
              
            }}, 1000)
      } else {

        showToast(false,'Efso Dijital', "Veriler işlenirken bir hata oluştu!")

      }
    } catch (error) {
      console.error('Error submitting data:', error);
      showToast(false,'Efso Dijital', "Veriler işlenirken bir hata oluştu!")
    }
  };

  function showToast(type,title,message) {

    let ele = null
  
     if(type == true){
       ele = "success"
       message = message
    } else{
       ele = "error"
       message = message 
    };
    const position = ["toast-top-right"];
    let toastType;
   
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  useEffect(() => {
    // Ders seçildiğinde state'i güncelle
    setSelectedLesson(getCategoryID);
  }, [getCategoryID]);


  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteBook(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleCategoryChange = (e) => {
    const selectedID = e.target.value;
    setSelectedLesson(selectedID);
    _setChoiceCategory(selectedID);
  };


  const _handleDelete = async(ID) => {
    try {
      let authUser = JSON.parse(localStorage.getItem("authUser"));

      axios.delete(`https://sihaadmin.stechomeyazilim.info:8182/deleteTestEfso/${ID}`).then(async(res) => {
        dispatch(onGetBook(authUser.ID));
        showToast(true,'Efso Dijital', "Veri başarıyla silindi!")
      })
      

    } catch (error) {
      console.error(error);
    }

  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Kullanıcı" breadcrumbItem="Kitap Listesi" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

<div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus me-1" />
                Deneme Ekle
              </Button>
            </div>


          {users.map((item, index) =>{  
          
          let correctAnswer = item.Test_CorrectAnswer;
          let targetAnswer = item.Target_CorrectAnswer;
          
          let difference = targetAnswer - correctAnswer;
          let percentageBehind = (difference / targetAnswer) * 100;

          return(       
            <Card
            key={index}
            className={`notification-card ${correctAnswer >= targetAnswer ? 'success' : 'fail'}`}>

<Button
                type="button"
                color="danger"
                className="btn-rounded "
                style={{position:'absolute',right:0,top:0}}
                onClick={()=> _handleDelete(item.ID)}
              >
                <i className="mdi mdi-delete " />
              
              </Button>

        <div className="card-title" style={{ borderBottom: '1px solid white' }}>
          <div>
            {correctAnswer >= targetAnswer ? (
              <p className="card-title-text" >
                % {Math.abs(parseFloat(percentageBehind).toFixed(0))} Hedefin Üzerindesin
              </p>
            ) : (
              <p className="card-title-text" >
                % {parseFloat(percentageBehind).toFixed(0)} Hedefin Gerisindesin
              </p>
            )}
            <p className="card-title-text">
              {moment(item.Created_DateTime).format('DD/MM/YYYY')} Tarihinde
              Oluşturuldu
            </p>
          </div>
        </div>

        <div className="card-content">
          <div className="card-info">
            <p className="card-title-subs">Deneme Adı: {item.Test_Title}</p>
            <p className="card-title-subs">Ders: {item.Lesson.Lesson_Title}</p>
            <p className="card-title-subs">Doğru Cevap: {item.Test_CorrectAnswer}</p>
            <p className="card-title-subs">Yanlış Cevap: {item.Test_FalseAnswer}</p>
            <p className="card-title-subs">Hedef Net: {item.Target_CorrectAnswer}</p>
            <p className="card-title-subs">
              Deneme Tarihi: {moment(item.Test_DateTime).format('DD/MM/YYYY')}
            </p>
          </div>
        </div>
      </Card>
         )})}
      
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Kitap Düzenle" : "Kitap Ekle"}
                    </ModalHeader>
                    <ModalBody>
                     
                        <Row>
                          <Col xs={12}>
                    
                            <div className="mb-3">
                              <Label className="form-label">Ders Seçiniz</Label>
                              <Input
                                name="getCategoryID"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={handleCategoryChange}
                                value={selectedLesson}>
                                 <option value="">Lütfen Seçim Yapınız</option>

                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Lesson_Title}</option>
                                ))}

                              </Input>
                              <form onSubmit={handleSubmit}>
                              {selectedLesson && (
        <div className="mb-3">
          <Label className="form-label">Net Sayısı</Label>
          <Input
            type="number"
            name="netSayisi"
            value={inputValues.netSayisi}
            onChange={handleInputChange}
            placeholder="Net Sayınızı Girin"
          />
          <Label className="form-label">Yanlış Sayısı</Label>
          <Input
            type="number"
            name="yanlisSayisi"
            value={inputValues.yanlisSayisi}
            onChange={handleInputChange}
            placeholder="Yanlış Sayınızı Girin"
          />
          <Label className="form-label">Hedef Net Sayısı</Label>
          <Input
            type="number"
            name="hedefNetSayisi"
            value={inputValues.hedefNetSayisi}
            onChange={handleInputChange}
            placeholder="Hedef Net Sayınızı Girin"
          />
          <Label className="form-label">Deneme Adı</Label>
          <Input
            type="text"
            name="denemeAdi"
            value={inputValues.denemeAdi}
            onChange={handleInputChange}
            placeholder="Deneme Adını Girin"
          />

<div className="mb-3">
            <Label className="form-label">Tarih</Label>
            <Input
              type="date"
              name="tarih"
              value={inputValues.tarih}
              onChange={handleInputChange}
            />
          </div>

        </div>
        
      )}
                            <div className="text-end">
                               <Button type="submit" color="primary">Gönder</Button>
                            </div>
      </form>
                            </div>


      
                          </Col>
                        </Row>
                       
                   
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
