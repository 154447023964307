import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";


// Pages Components
import WelcomeComp from "./WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";


import {

  getUsers as onGetUsers,

} from "store/contacts/actions";
import axios from "axios";
const Dashboard = props => {

  const dispatch = useDispatch();
  const [getPlan, setPlan] = useState([]);
  const [getHomework, setHomework] = useState([]);
  const [getMeeting, setMeeting] = useState([]);


  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));



  const reports = [
   

    {
      title:  "Toplam Ödev",
      iconClass: "bx-purchase-tag-alt",
      description:  getHomework.length,
    },


    {
      title:  "Toplam Görüşme",
      iconClass: "bx-purchase-tag-alt",
      description:  getMeeting.length,
    },


    {
      title:  "Toplam Planlama",
      iconClass: "bx-purchase-tag-alt",
      description:  getPlan.length,
    },

  ];




  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
    }
  }, [dispatch, users]);


  const _getAllMeeting = async () => {
    try {

      let authUser = JSON.parse(localStorage.getItem("authUser"));
      if (authUser != null) {
      await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getMeetingUserEfso1/select/${authUser.ID}`)
      .then((rsp) => {

        const dates = [];
        setMeeting(rsp.data)

  
        
      })
      
    }
     

    } catch (error) {
      console.error(error);
    }
  }


  const _getAllPlan = async () => {
    try {

      let authUser = JSON.parse(localStorage.getItem("authUser"));
      if (authUser != null) {
      await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getPlanUserEfso/select/${authUser.ID}/`)
      .then((rsp) => {

        const dates = [];
        setPlan(rsp.data)

      
      })
      
    }
     

    } catch (error) {
      console.error(error);
    }
  }

  const _getAllHomework = async () => {
    try {

      let authUser = JSON.parse(localStorage.getItem("authUser"));
      if (authUser != null) {
      await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getAllHomeworkUser/select/${authUser.ID}/`)
      .then((rsp) => {

        console.log("şlsmdfli",rsp.data)
        setHomework(rsp.data)

      
        
      })
      
    }
     

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
   
    if (getPlan && !getPlan.length) {

      _getAllPlan()
                }
  }, [getPlan]);

  useEffect(() => {
   
    if (getHomework && !getHomework.length) {

      _getAllHomework()
                }
  }, [getHomework]);



  useEffect(() => {
   
    if (getMeeting && !getMeeting.length) {

      _getAllMeeting()
                }
  }, [getMeeting]);


  //meta title
  document.title = "Anasayfa | EfsoKoç Kullanıcı Paneli";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Yönetim")}
            breadcrumbItem={props.t("Anasayfa")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>

                 
                    
                         

                  </Col>
                ))}
              </Row>

          
           
             
            </Col>

           



          </Row>

         
         
        </Container>
      </div>

   
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
