import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import withRouter from "components/Common/withRouter";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { getLimit as onGetLimit } from "store/contacts/actions";
import { isEmpty } from "lodash";

const ContactsList = (props) => {
  document.title = "Koçlar | EfsoKoç ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();

  const { users } = useSelector((state) => ({
    users: state.contacts.limit,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetLimit());
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
  }, [users]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Yönetim" breadcrumbItem="Koçlar" />
          <Row>
            {users.map((teacher) => {
              console.log("şösmdfş",teacher)
              return(
              <Col lg="12" key={teacher.Users_ID} className="mb-4">
                <Card className="teacher-card">
                  <CardBody className="teacher-card-body">
                    <Row>
                      <Col lg="2" className="teacher-image-col">
                        <img
                          src={'https://megasubdomain.stechomeyazilim.info/' + teacher.Teacher_Url}
                          alt={teacher.Teacher_NameSurname}
                          className="img-fluid teacher-image"
                        />
                      </Col>
                      <Col lg="10">
                        <h3 className="teacher-name">
                          {teacher.Teacher_NameSurname}
                        </h3>

                        
                        <p className="teacher-shortdesc">
                          {teacher.Teacher_ShortDesc}
                        </p>

                        <div dangerouslySetInnerHTML={{ __html: teacher.Teacher_Desc }} />


                  
                        <p className="teacher-history">
                          Geçmiş: {teacher.Teacher_History}
                        </p>
                        <p className="teacher-experience">
                          Deneyim: {teacher.Teacher_Experience} yıl
                        </p>
                        <p className="teacher-price">
                          Ücret: {teacher.Teacher_Price} TL
                        </p>
                        <p className="teacher-contact">
                          İletişim: {teacher.Customer_TelephoneNumber}
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )})}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
