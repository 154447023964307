import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { getSliders as onGetSliders } from "store/contacts/actions";

const AnnouncementList = (props) => {
  const dispatch = useDispatch();
  const { users } = useSelector(state => ({
    users: state.contacts.sliders,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetSliders());
    }
  }, [dispatch, users]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Yönetim" breadcrumbItem="Duyurular Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {users.map((item, index) => (
                    <div key={index} className="announcement-card">
                      <div className="announcement-image">
                        <img src={'https://megasubdomain.stechomeyazilim.info/' + item.Announcement_Image} alt="Announcement" />
                      </div>
                      <div className="announcement-text">
                        <p>{item.Announcement_Text}</p>
                      </div>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AnnouncementList;
