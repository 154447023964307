import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateAbout as onUpdateAbout,
  getAbout as onGetAbout,
} from "store/contacts/actions";
import toastr from 'toastr'; // toastr kütüphanesini içe aktarın
import 'toastr/build/toastr.min.css'; // toastr stillerini içe aktarın

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Base64 } from 'js-base64';

const EcommerceAddProduct = () => {
  document.title = "Hakkımızda Bilgileri | EfsoKoç B2B Paneli";

  const dispatch = useDispatch();

  const [getContactEdit, setContactEdit] = useState(null);
  const [getHtmlDataLong, setHtmlDataLong] = useState(null);
  const [getHtmlDataKVKK, setHtmlDataKVKK] = useState(null);

  const [getContent, setContent] = useState(
    {
      Company_Name : null,
      Company_Adress : null,
      Company_TelephoneNumber : null,
      Company_WebAdress : null,
      Company_MailAdress : null,

    }
  );


  
  const showToast= async (type) => {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla güncellendi."
    } else{
       ele = "error"
       message = "type"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {


      title: (getContactEdit && getContactEdit.Company_Name) || '',
      adress:(getContactEdit && getContactEdit.Company_Adress) || '',
      phoneNumber:(getContactEdit && getContactEdit.Company_TelephoneNumber) || '',
      faxNumber:(getContactEdit && getContactEdit.Company_FaxNumber) || '',
      webAdress:(getContactEdit && getContactEdit.Company_WebAdress) || '',
    

    },
    validationSchema: Yup.object({
      title: Yup.string().required("Lütfen Başlık Giriniz")
    }),
    onSubmit: (values) => {

      const updateOrder = {
        ID: users ? users[0].ID : 0,
        Company_Name:values.title,
        Company_Adress:values.adress,
        Company_TelephoneNumber:values.phoneNumber,
        Company_FaxNumber:values.faxNumber,
        Company_WebAdress:values.webAdress,
        Company_About: Base64.encode(getHtmlDataLong),
        About_KVKK: Base64.encode(getHtmlDataKVKK),
      };
      // update order
      dispatch(onUpdateAbout(updateOrder));

      showToast(true)

      dispatch(onGetAbout());

    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.about,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetAbout());

    }
  }, [dispatch, users]);

  useEffect(() => {
    if(users.length > 0){
      setContactEdit(users[0]);

      if(users[0].About_KVKK != null){
        setContent({
          ...getContent,
          Company_Name : users[0].Company_Name,
          Company_Adress : users[0].Company_Adress,
          Company_TelephoneNumber : users[0].Company_TelephoneNumber,
          Company_WebAdress : users[0].Company_WebAdress,
          Company_MailAdress : users[0].Company_MailAdress,

        })
        setHtmlDataKVKK(Base64.decode(users[0].About_KVKK))
      }
      if(users[0].Company_About != null){
        setHtmlDataLong(Base64.decode(users[0].Company_About))
      }

      
    }
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Hakkımızda Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Hakkımızda Bilgileri</CardTitle>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>

                      <div className="mb-3">
                        <Label htmlFor="productname">Başlık - </Label>
                        <a>{getContent.Company_Name}</a>
                      
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="productname">Telefon - </Label>
                        <a>{getContent.Company_TelephoneNumber}</a>

                      </div>

                      <div className="mb-3">
                      <Label htmlFor="productname">Adres - </Label>

                      <a>{getContent.Company_Adress}</a>

                      </div>

                      <div className="mb-3">
                      <Label htmlFor="productname">Web Adresi - </Label>

                      <a>{getContent.Company_WebAdress}</a>

                      </div>

                      <div className="mb-3">
                      <Label htmlFor="productname">Mail Adresi - </Label>

                      <a>{getContent.Company_MailAdress}</a>

                      </div>

                      <div className="mb-3">
                              <Label className="form-label">Uzun Açıklama - </Label>
                              <a>{getContent.Company_MailAdress}</a>

                            
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">KVKK</Label>

                              <div dangerouslySetInnerHTML={{ __html: getHtmlDataKVKK }} />

                             
                            </div>

                    </Row>
                   
                  </Form>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
