import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Email, GSM,Date,Status } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import jsPDF from "jspdf";
import "jspdf-autotable";


import moment from "moment";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Kullanıcılar | Nefes Al ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const [getUsersTypeID, setUsersTypeID] = useState(null);


  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Users_NameSurname) || "",
      password: (contactEdit && contactEdit.Users_LoginPasswordCode) || "",
      telephoneNumber: (contactEdit && contactEdit.Users_TelephoneNumber) || "",

    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),
    
    }),
    onSubmit: values => {
      if (isEdit) {
        var localDate = moment().local();

        const updateUser = {
          ID: contactEdit.ID,
          Users_NameSurname:values.title,
          Users_LoginPasswordCode:  values.password,
          Users_TelephoneNumber: values.telephoneNumber,
          Users_Type_ID:getUsersTypeID
        };
        dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Users_NameSurname:values["title"],
          Users_LoginPasswordCode:  values["password"],
          Users_TelephoneNumber: values["telephoneNumber"],
          Users_Type_ID:getUsersTypeID
        };
        // save new user
        dispatch(onAddNewUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.forum,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getAuthData, setAuthData] = useState([]);
      

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Adı",
        accessor: "Users_NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
     
      {
        Header: "Kul.Tipi",
        accessor: "Users_Type.Users_Type_Text",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
     

      {
        Header: 'Kayıt Tarihi',
        accessor: 'Users_CreatedDateTime',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },

      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData= async () => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersTypeNefes/select/`)
    .then((res) => {
      setAuthData(res.data)
    })
  }

  useEffect(() => {
    _getAuthData()
    if (users && !users.length) {
      dispatch(onGetUsers());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
   // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);



    setUsersTypeID(user.Users_Type_ID)

    setIsEdit(true);
 

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteUser(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceAuth = arg => {
    setUsersTypeID(arg.target.value)
   }

   const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }


  const keyField = "id";
  const fileName = "myfile"; // here enter filename for your excel file

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Kullanıcı Listesi";
    const headers = [["Adı", "Telefon Numarasi", "İlçe", "Adres", "Mail Adresi"]];

    const data = users.map(elt=> [elt.NameSurname, elt.GsmNumber, elt.District, elt.Adress, elt.MailAdress]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
  }



  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Kullanıcı Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
      
                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddEducationList={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kullanıcı Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                       
                        
                      
                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Adı</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Kullanıcı Adını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                          
                          
                            <div className="mb-3">
                              <Label className="form-label">Şifre</Label>
                              <Input
                                name="password"
                                label="password"
                                type="text"
                                placeholder="Şifre Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                    validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.password &&
                                validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Telefon Numarası</Label>
                              <Input
                                name="telephoneNumber"
                                label="telephoneNumber"
                                type="text"
                                placeholder="Telefon Numarası Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.telephoneNumber || ""}
                                invalid={
                                  validation.touched.telephoneNumber &&
                                    validation.errors.telephoneNumber
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.telephoneNumber &&
                                validation.errors.telephoneNumber ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.telephoneNumber}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                      <Label className="form-label">Kullanıcı Tipi Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        defaultValue={getUsersTypeID}
                        onChange={(text) => _setChoiceAuth(text)}
                        value={
                          getUsersTypeID
                        }>
                        {getAuthData.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Users_Type_Text}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>

                 

                


                          </Col>
                        </Row>
                        <Row>


                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
