import React, { useEffect, useState, useRef, useMemo } from "react";
import withRouter from "components/Common/withRouter";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";
import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css'; // Takvim için gerekli CSS dosyası
import './EventCard.css'; // Takvim için gerekli CSS dosyası


import {
  getProductSubSubCategory as onGetProductSubSubCategory,
  addProductSubSubCategory as onAddProductSubSubCategory,
  updateSubSubProductCategory as onUpdateSubSubProductCategory,
  deleteProductSubSubCategory as onDeleteProductSubSubCategory,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Ödevler | EfsoKoç Dijital Kullanıcı PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getSubCategoryID, setSubCategoryID] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());


  const { users } = useSelector(state => ({
    users: state.contacts.productSubSubCategory,
  }));

  const [modal, setModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [getFilterCheck, setFilterCheck] = useState(false);

  const [getCategory, setCategory] = useState([]);

  const [getPlan, setPlan] = useState([]);
  const [markedDates, setMarkedDates] = useState([]);

  const _getAuthData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getLessonEfso/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  const _getAllHomework = async () => {
    try {

      let authUser = JSON.parse(localStorage.getItem("authUser"));
      if (authUser != null) {
      await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getAllHomeworkUser/select/${authUser.ID}/`)
      .then((rsp) => {

        console.log("şlsmdfli",rsp.data)
        const dates = [];
        setPlan(rsp.data)

        rsp.data.forEach(event => {
          const startDate = moment(event.Homework_DateTime).format('YYYY-MM-DD');
          dates.push(startDate); // Tarihi diziye ekle
        });
        setMarkedDates(dates);
        
      })
      
    }
     

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    _getAllHomework()
    if (users && !users.length) {

      let authUser = JSON.parse(localStorage.getItem("authUser"));

      if (authUser.Users_Type_ID == 2) {
        dispatch(onGetProductSubSubCategory(authUser.ID))
        setIsEdit(false);

      }
      else{
        dispatch(onGetProductSubSubCategory(null))
        setIsEdit(false);

      }
                }
  }, [dispatch, users]);

  useEffect(() => {
    _getAuthData()
   
  }, [getCategory]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteProductSubSubCategory(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };


  const renderEventSlots = () => {
    if (!selectedDate) return null;

    const eventSlots = Array.from({ length: 24 }, (_, index) => {
      const hour = String(index).padStart(2, '0');
      const fullHour = `${hour}:00`;
      const nextHour = `${String(index + 1).padStart(2, '0')}:00`;
      const event = getPlan.find(event => {
        const startHour = event.Homework_DateTime.split('T')[1].slice(0, 5);
        const finishHour = event.Homework_DateTime.split('T')[1].slice(0, 5);
        return fullHour >= startHour && fullHour < finishHour;
      });

      return (
        event && selectedDate ? 
        <div key={fullHour} className="hourSlot">
          <p className="textCenter">{`${fullHour} - ${nextHour}`}</p>
          {event && selectedDate === event.Homework_DateTime.split('T')[0] ? (
            <div>
              <p className="eventInfo">
                {`${event.Homework_DateTime.split('T')[1].slice(0, 5)}: ${event.Users.Users_NameSurname}`}
              </p>
              {event.HomeWork_Note && <p className="eventInfo">{event.HomeWork_Note}</p>}
            </div>
          ) : (
            <p className="noEvent">Ders Yok</p>
          )}
        </div> : null
      );
    });

    return eventSlots;
  };


  const _selectStatus = async(status,ID) => {
  
    try {

      await axios.patch(`https://sihaadmin.stechomeyazilim.info:8182/updateHomeWorkEfso/${ID}`, {
        Homework_Status : status
      })
      .then(async (res) => {
        _getAllHomework()
      })

     
    

    } catch (error) {
      console.error('Paylaşım hatası:', error);
    }

     
  };

  const handleUserReset = (arg) => {
    setFilterCheck(false)
  };

  const handleDateClick = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setSelectedDate(formattedDate);
    const filtered = getPlan.filter(event => moment(event.Homework_DateTime).format('YYYY-MM-DD') === formattedDate);
    setFilteredData(filtered);
    setFilterCheck(true)
  };


  const renderWeeklySchedule = () => {
    const daysOfWeek = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];

    const weekStart = moment().startOf('week'); 

    const weekSchedule = daysOfWeek.map((day, index) => {
      const currentDay = weekStart.clone().add(index, 'days').format('YYYY-MM-DD');

      const events = getPlan.filter(event => event.Homework_DateTime.split('T')[0] === currentDay);

      return (
        events.length > 0 ? 
        events.map((item, index) =>{  
          return(

          <div key={currentDay} className="card-container">
            <p className="created-date">
            { moment(item.Created_DateTime).format('DD/MM/YYYY')}
            </p>

            <div className="event-info-row">
              <div>
                <div className="info-item">
                  <p className="event-label">Tarih</p>
                </div>
                <div className="info-item">
                  <p className="event-label">Gün</p>
                </div>
                <div className="info-item">
                  <p className="event-label">Saat</p>
                </div>
                {events.length > 0 && item.Lesson && (
                  <div className="info-item">
                    <p className="event-label">Ders</p>
                  </div>
                )}
                {events.length > 0 && item.HomeWork_Note && (
                  <div className="info-item">
                    <p className="event-label">Not</p>
                  </div>
                )}
                {events.length > 0 && item.Homework_Status && (
                  <div className="info-item">
                    <p className="event-label">Durumu</p>
                  </div>
                )}
              </div>

              <div>
                <div className="info-item">
                  <p className="event-info">{currentDay}</p>
                </div>
                <div className="info-item">
                  <p className="event-info">{day}</p>
                </div>
                <div className="info-item">
                  <p className="event-info">
                    {events.length > 0 ? `${item.Homework_DateTime.split('T')[1].slice(0, 5)}` : ''}
                  </p>
                </div>
                {events.length > 0 && item.Lesson && (
                  <div className="info-item">
                    <p className="event-info">{item.Lesson.Lesson_Title}</p>
                  </div>
                )}
                {events.length > 0 && item.HomeWork_Note && (
                  <div className="info-item">
                    <p className="event-info">{item.HomeWork_Note}</p>
                  </div>
                )}
                {events.length > 0 && item.Homework_Status && (
                  <div className="info-item">
                    <p className="event-info">{item.Homework_Status}</p>
                  </div>
                )}
              </div>
            </div>

            <div className="button-row">
              {events.length > 0 && item.Homework_Status !== 'yapıldı' && (
                <button className="status-button green" onClick={() => _selectStatus('yapıldı', item.ID)}>
                  Yapıldı
                </button>
              )}
              {events.length > 0 && item.Homework_Status !== 'eksik' && (
                <button className="status-button gray" onClick={() => _selectStatus('eksik', item.ID)}>
                  Eksik Yaptı
                </button>
              )}
              {events.length > 0 && item.Homework_Status !== 'yapılmadı' && (
                <button className="status-button red" onClick={() => _selectStatus('yapılmadı', item.ID)}>
                  Yapılmadı
                </button>
              )}
            </div>
          </div>
        ) }): null
      )
    });

    return weekSchedule; 
  };

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = moment(date).format('YYYY-MM-DD');
   
      return markedDates.includes(formattedDate) ? 'highlighted' : null
    }
    return null;
  };
  
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Ödev Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

              
             
      <div className="calendar-container">
        <Calendar
          onChange={setSelectedDate}
          value={selectedDate}
          tileClassName={tileClassName}
          onClickDay={handleDateClick}
                  />
        </div>
   
        { getFilterCheck == true ?  
        <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleUserReset}
              >
                <i className="mdi mdi-refresh me-1" />
                Haftalık Planlamayı Göster
              </Button> : 
             null}

        
{ getFilterCheck == true ? 
        filteredData.map((item, index) => (
          
          <div key={index} className="card-container">
          <p className="created-date">
          { moment(item.Created_DateTime).format('DD/MM/YYYY')}
          </p>

          <div className="event-info-row">
            <div>
              <div className="info-item">
                <p className="event-label">Tarih</p>
              </div>
              <div className="info-item">
                <p className="event-label">Gün</p>
              </div>
              <div className="info-item">
                <p className="event-label">Saat</p>
              </div>
             { item.Lesson && (
                <div className="info-item">
                  <p className="event-label">Ders</p>
                </div>
              )}
            {item.HomeWork_Note && (
                <div className="info-item">
                  <p className="event-label">Not</p>
                </div>
              )}
          {item.Homework_Status && (
                <div className="info-item">
                  <p className="event-label">Durumu</p>
                </div>
              )}
            </div>

            <div>
              <div className="info-item">
                <p className="event-info">{moment(item.Homework_DateTime).format('DD/MM/YYYY')}</p>
              </div>
              <div className="info-item">
                <p className="event-info">{moment(item.Homework_DateTime).format('dddd')}</p>
              </div>
              <div className="info-item">
                <p className="event-info">
                  {item.Homework_DateTime.split('T')[1].slice(0, 5)}
                </p>
              </div>
             {item.Lesson && (
                <div className="info-item">
                  <p className="event-info">{item.Lesson.Lesson_Title}</p>
                </div>
              )}
            {item.HomeWork_Note && (
                <div className="info-item">
                  <p className="event-info">{item.HomeWork_Note}</p>
                </div>
              )}
           {  item.Homework_Status && (
                <div className="info-item">
                  <p className="event-info">{item.Homework_Status}</p>
                </div>
              )}
            </div>
          </div>

          <div className="button-row">
            {item.Homework_Status !== 'yapıldı' && (
              <button className="status-button green" onClick={() => _selectStatus('yapıldı', item.ID)}>
                Yapıldı
              </button>
            )}
       {  item.Homework_Status !== 'eksik' && (
              <button className="status-button gray" onClick={() => _selectStatus('eksik', item.ID)}>
                Eksik Yaptı
              </button>
            )}
            {item.Homework_Status !== 'yapılmadı' && (
              <button className="status-button red" onClick={() => _selectStatus('yapılmadı', item.ID)}>
                Yapılmadı
              </button>
            )}
          </div>
        </div>
  )):

         <div>{renderWeeklySchedule()}</div>}


        
                 {/*
                 renderEventSlots()
                 <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddProductCategory={false}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  /> */} 

        

            
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
