import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Email, Date as ContactDate } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getCounter as onGetCounter,
  addCounter as onAddCounter,
  updateCounter as onUpdateCounter,
  deleteCounter as onDeleteCounter,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";

// Sayaç Bileşeni
const CountdownTimer = ({ targetDate, title }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  function calculateTimeLeft(date) {
    const parsedDate = new Date(date);

    if (isNaN(parsedDate)) {
      console.error("Geçersiz tarih formatı:", date);
      return {};
    }

    const difference = +parsedDate - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  return (
    <div className="countdown-timer">
      <h2>{title}</h2>
      <div className="time-box">
        <span className="days">{timeLeft.days || 0} Gün</span>{" "}
        <span className="hours">{timeLeft.hours || 0} Saat</span>{" "}
        <span className="minutes">{timeLeft.minutes || 0} Dakika</span>{" "}
        <span className="seconds">{timeLeft.seconds || 0} Saniye</span>
      </div>
    </div>
  );
};

const ContactsList = props => {
  //meta title
  document.title = "Sayaç | EfsoKoç ADMIN PANELİ";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEditContactTeacher, setEditContactTeacher] = useState();

  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (getEditContactTeacher && getEditContactTeacher.Counter_Title) || "",
      startDate: (getEditContactTeacher && moment(getEditContactTeacher.DateTime).format('YYYY-MM-DD')) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Sayaç adı gerekli"),
      startDate: Yup.string().required("Başlangıç tarihi gerekli"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          ID: contact.ID,
          Counter_Title: values.name != null ? values.name : null,
          DateTime: moment(values.startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
        };
        console.log("Güncellenen Kullanıcı:", updateUser);
        // update user
        dispatch(onUpdateCounter(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Counter_Title: values.name != null ? values.name : null,
          DateTime: moment(values.startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
        };

        console.log("Yeni Kullanıcı:", newUser);
        dispatch(onAddCounter(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.counter,
  }));

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value);
  };

  const _getAuthData = async () => {
    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getLessonSubSubjectEfso/select/`)
      .then((res) => {
        setCategory(res.data);
      });
  };

  useEffect(() => {
    _getAuthData();
  }, [dispatch, getCategory]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Başlık",
        accessor: "Counter_Title",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Tarih",
        accessor: "DateTime",
        filterable: true,
        Cell: cellProps => {
          return <ContactDate {...cellProps} />;
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetCounter());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setEditContactTeacher(user);
    setContact(user);
    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteCounter(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Kullanıcı" breadcrumbItem="Sayaç" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {users.map((counter) => (
                    <CountdownTimer
                      key={counter.ID}
                      title={counter.Counter_Title}
                      targetDate={counter.DateTime}
                    />
                  ))}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
