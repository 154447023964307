import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname } from "./contactlistCol";
import classnames from "classnames";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";


import Dropzone from 'react-dropzone-uploader';


import {
  getPdr as onGetPdr,
  addPdr as onAddPdr,
  updatePdr as onUpdatePdr,
  deletePdr as onDeletePdr,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Pdr Sorular | EfsoKoç ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getFileName, setFileName] = useState('');
  const [activeTab, setActiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [options, setOptions] = useState([{ text: '', correct: false }]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      sort: (contactEdit && contactEdit.NumberOfQuestion) || "",


    },
    validationSchema: Yup.object({
    


    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Question_Title_Image: getFileName,
         //   Question_HtmlContent: Base64.encode(getHtmlDataLong),
          NumberOfQuestion: values.sort,
          Question_SubCategory_Sub_ID:getSubCategoryID,
        };
        dispatch(onUpdatePdr(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Question_Title_Image: getFileName,
          Question_SubCategory_Sub_ID:getSubCategoryID,
        //Question_HtmlContent: Base64.encode(getHtmlDataLong),
          NumberOfQuestion: values["sort"],
        };
        // save new user
        dispatch(onAddPdr(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.pdr,
  }));

  const [modal, setModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [getCategory, setCategory] = useState([]);
  const [getSubCategoryID, setSubCategoryID] = useState();

  const _setChoiceSubCategory = arg => {
    setSubCategoryID(arg.target.value)
  }

  
  const toggleTab = tab => {

    console.log("tab123213",tab)
    if(tab > 0){

      if (activeTab !== tab) {
        const modifiedSteps = [...passedSteps];
        modifiedSteps.push(tab);
        setActiveTab(tab);
        setPassedSteps(modifiedSteps);
      }

    if(tab == 2){
      
      const state = {
        QuestionPdr_Title_Image: getFileName,
        PdrCategory_ID:getSubCategoryID,
        NumberOfQuestion: 1,
    };
    localStorage.setItem('questionPdrState', JSON.stringify(state));
    

    /*  var localDate = moment().local();

      const newUser = {
        Users_ID:getUserID,
        Payment_Select_ID:getPaymentID,
        Created_DateTime:localDate,
        //Orders_TotalPrice:values["price"],
        Orders_IsActive:getIsActive,
        //Payment_Type:getPaymentID.toString()
      };

      axios.post('https://sihaadmin.stechomeyazilim.info:8182/addOrderDemir/send', newUser) .then((res) => {

      console.log("lmsdşl",res.data.value)

      setOrderID(res.data.value[0].ID)

      if(res.data.value.length >0){
       
  
      }
     

      }) */
      
    }
    

  }
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.QuestionPdr_Title_Image ? (
              <div className="avatar-xl">
                {cellProps.QuestionPdr_Title_Image != null ? <span className="avatar-title rounded-circle">
                  {cellProps.QuestionPdr_Title_Image.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className=" avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.QuestionPdr_Title_Image}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Kategori",
        accessor: "PdrCategory.PdrCategory_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Sıralama",
        accessor: "NumberOfQuestion",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      
      {
        Header: "Cevaplar",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
          
          {cellProps.QuestionPdr_Answer.length > 0 ? cellProps.QuestionPdr_Answer.map((item, index) => (
                                 <div key={index}>
                                 <a  className={item.QuestionPdr_Answer_CheckTrue == true ? "text-success" : "text-danger"}>{item.QuestionPdr_Answer_Text} - {item.QuestionPdr_Answer_CheckTrue == true ? "Doğru" : "Yanlış"}</a>
                               </div>
                                )) : null}


            
          
          </>
        ),
      },

      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

        
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getPdrCategoryEfso/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()
    if (users && !users.length) {
      dispatch(onGetPdr());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };


  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);
    //Question_SubCategory_Sub_ID

    setSubCategoryID(user.Question_SubCategory_Sub_ID)
    setFileName(user.Question_Title_Image)
    setIsEdit(true);


    toggle();
  };


  

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeletePdr(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };



  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }



  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }


  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index].text = value;
    setOptions(newOptions);
  };

  const handleOptionCorrectChange = (id) => {
    const newOptions = options.map(option =>
      option.id === id ? { ...option, correct: !option.correct } : option
    );
    setOptions(newOptions);
  };

  const handleSubmit = async () => {

      const savedState = JSON.parse(localStorage.getItem('questionPdrState'));

      console.log("savedState1233",savedState)
      axios.post('https://sihaadmin.stechomeyazilim.info:8182/addQuestionPdrEfso/send', savedState) .then((res) => {


        if(res.data.value.length > 0){
          options.forEach((item, index) => {

          const newQAnswer = {
            QuestionPdr_ID:res.data.value[0].ID,
            QuestionPdr_Answer_Text:item.text,
            QuestionPdr_Answer_CheckTrue:item.correct
          };
  
          axios.post('https://sihaadmin.stechomeyazilim.info:8182/addQuestionPdrAnswerEfso/send', newQAnswer) .then((res) => {
  

            //console.log("şmösdfşl",res.data.value)
          })
          })

          showToast('EfsoKoç', "Soru Detayları Başarıyla Girilmiştir", true)
          setIsEdit(false);

          dispatch(onGetPdr(null))

          toggle();
          setCategory([])
          setActiveTab(1)
        }
       
    

    
    });
  };


  function showToast(type,message,type2) {

    let ele = null
  
     if(type2 == true){
       ele = "success"
       message = message
    } else{
       ele = "error"
       message = message 
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const addOption = () => {
    const newId = options.length > 0 ? options[options.length - 1].id + 1 : 1;
    setOptions([...options, { id: newId, text: '', correct: false }]);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Pdr Sorular" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddPdr={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

           

                  <Modal size="xl" isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Soru Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

<div id="kyc-verify-wizard" className="wizard clearfix">
                          <div className="steps clearfix">
                            <ul>
                              <NavItem
                                className={classnames({
                                  current: activeTab === 1,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 1,
                                  })}
                                  onClick={() => {
                                    toggleTab(1);
                                  }}
                                  disabled={!passedSteps.includes(1)}
                                >
                                  <span className="number">1.</span>
                                Soru Bilgileri
                                </NavLink>
                              </NavItem>
                              <NavItem
                                className={classnames({
                                  current: activeTab === 2,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 2,
                                  })}
                                  onClick={() => {
                                    toggleTab(2);
                                  }}
                                  disabled={!passedSteps.includes(2)}
                                >
                                  <span className="number">2.</span>
                                  Seçenek Bilgileri
                                </NavLink>
                              </NavItem>
                           
                            </ul>
                          </div>
                          <div className="content clearfix">
                            <TabContent
                              activeTab={activeTab}
                              className="twitter-bs-wizard-tab-content"
                            >
                              <TabPane tabId={2} id="personal-info">
                                  <Form onSubmit={handleSubmit}>

                                  <Row>
                                    <Col lg="6">
                                      <FormGroup className="mb-3">
                                    
                      
                         {options.map((option, index) => (
          <div className="mb-3" key={option.id}>
            
            <label className="form-check-label" htmlFor={option.id}>
              {`Seçenek ${index + 1}`}
            </label>
            <input
              type="text"
              value={option.text}
              onChange={(e) => handleOptionChange(index, e.target.value)}
              placeholder={`Seçenek ${index + 1}`}
              style={{ marginRight: '10px' }}
            />
            <button type="button" onClick={() => handleOptionCorrectChange(option.id)}>
              {option.correct ? 'Doğru' : 'Yanlış'}
            </button>
          </div>
        ))}
        <button type="button" onClick={addOption}>+ Seçenek Ekle</button>

                                      </FormGroup>
                                    </Col>
                                   
                   
                  
                               
                                  </Row>

                            
                                </Form>
                              </TabPane>
                              <TabPane tabId={1} id="confirm-email">
                                <div>
                                  <Form>
                                    <Row className="d-flex">

                                    <Col xl="6">
                     
                        
                        <div className="mb-3">
                              <Label className="form-label">Soru Sayısı</Label>
                              <Input
                                name="sort"
                                label="sort"
                                type="text"
                                placeholder="Soru Sayı Numarasını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sort || ""}
                                invalid={
                                  validation.touched.sort &&
                                    validation.errors.sort
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.sort &&
                                validation.errors.sort ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sort}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col xl="6">
                            <div className="mb-3">
                              <Label className="form-label">Kategori Seçiniz</Label>
                              <Input
                                name="getSubCategoryID"
                                type="select"
                                className="form-select"
                                defaultValue={getSubCategoryID}
                                onChange={(text) => _setChoiceSubCategory(text)}
                                value={
                                  getSubCategoryID
                                }>
                                 <option value="">Lütfen Seçim Yapınız</option>
                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.PdrCategory_Title}</option>
                                ))}

                              </Input>
                             
                            </div>
                            </Col>

                            <Col xl="6">

                            <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>
                            </div>
                            </Col>

                                      </Row>
                                      
                                   
                                 
                                  </Form>
                                </div>
                              </TabPane>
                             
                            </TabContent>
                          </div>
                          <div className="actions clearfix">
                            <ul role="menu" aria-label="Pagination">
                              <li
                                className={
                                  activeTab === 1
                                    ? "previous disabled"
                                    : "previous"
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTab(activeTab - 1);
                                  }}
                                >
                                  Geri
                                </Link>
                              </li>
                              {activeTab === 2 ? 
                                  
                                 
                                 <Link
                                 to="#"
                                 onClick={() => {
                                   handleSubmit(activeTab - 1);
                                 }}
                               >
                                  Seçenekleri Kaydet
                                  </Link>
                                 : 
                                 <li
                                className={
                                  activeTab === 2 ? "next disabled" : "next"
                                }
                              >
                                {isEdit == true ? 
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTabEdit(activeTab + 1);
                                  }}
                                >
                                  Siparişi Düzenle Ve Ürün Detaylarını Gir
                                </Link> : 
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTab(activeTab + 1);
                                  }}
                                >
                                  Seçenekleri Gir
                                </Link>}

                              </li>}
                            </ul>
                          </div>
                        </div>

               
                
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
