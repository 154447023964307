import axios from "axios";
import { del, get, post, put, patch } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get Events

// add Events

// update Event


// delete Event
export const deleteEvent = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteEventMega/${project}`);


// get Categories

// get chats

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages


// post messages
export const getselectedmails = (selectedmails) => post(url.GET_SELECTED_MAILS, selectedmails);

//post setfolderonmails
export const setfolderonmails = (selectedmails, folderId, activeTab) => post(url.SET_FOLDER_SELECTED_MAILS, { selectedmails, folderId, activeTab });

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get crypto product
export const getCryptoProduct = () => get(url.GET_CRYPTO_PRODUCTS);

// get invoices
export const getInvoices = () => get(url.GET_INVOICES);

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST);

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB);

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);

export const getEducationSubItemUsers = user => get(`https://sihaadmin.stechomeyazilim.info:8182/getPaymentUser/select/${user}`);

export const getEducationSubItem = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getNotificationEfso/select/`);
export const addSubEducation = user => post(`https://sihaadmin.stechomeyazilim.info:8182/addPracticeNotificationEfso/send`, user);
export const updateSubEducation = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updatePracticeNotificationEfso/${user.ID}`, user);
export const deleteSubEducation = user => del(`https://sihaadmin.stechomeyazilim.info:8182/deletePracticeNotificationEfso/${user}`);

export const addEducation = user => post(`https://sihaadmin.stechomeyazilim.info:8182/addEducationNefes/send`, user);

export const getCategory = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getQuestionCategoryEfso/select/`);
export const addProductCategory = user => post(`https://sihaadmin.stechomeyazilim.info:8182/addQuestionCategoryEfso/send`, user);
export const deleteProductCategory = user => del(`https://sihaadmin.stechomeyazilim.info:8182/deleteQuestionCategoryEfso/${user}`);
export const updateProductCategory = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateQuestionCategoryEfso/${user.ID}`, user);

export const addBlog = user => post(`https://sihaadmin.stechomeyazilim.info:8182/addQuestionEfso/send`, user);
export const deleteBlog = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteQuestionEfso/${project}`);
  export const updateBlog = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateQuestionEfso/${user.ID}`, user);
  export const getBlogs = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getQuestionEfso/select/`);
  
  export const addPdr = user => post(`https://sihaadmin.stechomeyazilim.info:8182/addQuestionPdrEfso/send`, user);
  export const deletePdr = project =>
    del(`https://sihaadmin.stechomeyazilim.info:8182/deleteQuestionPdrEfso/${project}`);
    export const updatePdr = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateQuestionPdrEfso/${user.ID}`, user);
    export const getPdr = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getQuestionPdrEfso/select/`);
    
    export const getSongCategory= () => get(`https://sihaadmin.stechomeyazilim.info:8182/getSongCategoryEfso/select/`);
export const addSongCategory = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addSongCategoryEfso/send`, job);
export const updateSongCategory = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateSongCategoryrEfso/${user.ID}`, user);
export const deleteSongCategory= user => del(`https://sihaadmin.stechomeyazilim.info:8182/deleteSongCategoryEfso/${user}`);


export const updateSong = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateSongEfso/${user.ID}`, user);
export const deleteSong= user => del(`https://sihaadmin.stechomeyazilim.info:8182/deleteSongEfso/${user}`);
export const addSong = user => post(`https://sihaadmin.stechomeyazilim.info:8182/addSongEfso/send`, user);

export const addNewPost= job => post(`https://getjob.stechomeyazilim.info:5101/addNewPostEfso/send`, job);

export const updateEducation = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateEducationNefes/${user.ID}`, user);

export const getFeedBack = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getFeedBackKupon/select/`);
export const updateFeedBack = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateFeedBackKupon/${user.ID}`, user);
export const addFeedBack= job => post(`https://kuponadmin.stechomeyazilim.info:9595/addFeedBackKupon/send`, job);
export const deleteFeedBack= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteFeedBackKupon/${project}`);

export const getFeedBackCategory = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getFeedBackCategoryKupon/select/`);
export const updateFeedbackCategory = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateFeedbackCategory/${user.ID}`, user);
export const addFeedBackCategory= job => post(`https://kuponadmin.stechomeyazilim.info:9595/addFeedBackCategory/send`, job);
export const deleteFeedBackCategory= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteFeedBackCategory/${project}`);


export const getPurch = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getPurchEfso/select/`);
export const updatePurch = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updatePurchEfso/${user.ID}`, user);
export const addPurch= job => post(`https://sihaadmin.stechomeyazilim.info:8182/addPurchEfso/send`, job);
export const deletePurch= project =>
del(`https://sihaadmin.stechomeyazilim.info:8182/deletePurchEfso/${project}`);




export const getPostCoach = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getPostCoach/select/`);
export const updatePostCoach = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updatePostCoach/${user.ID}`, user);
export const addPostCoach = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addPostCoach/send`, job);
export const deletePostCoach = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deletePostCoach/${project}`);


export const getBookUser = data => get(`https://sihaadmin.stechomeyazilim.info:8182/getTestUserEfso/select/${data}`,);

export const getBook = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getTestEfso/select/`);
export const updateBook = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateTestEfso/${user.ID}`, user);
export const addBook = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addTestEfso/send`, job);
export const deleteBook = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteTestEfso/${project}`);


export const getMotivation = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getMotivationalQuotesEfso/select/`);
export const updateMotivation = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateMotivationalQuotesEfso/${user.ID}`, user);
export const addMotivation = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addMotivationalQuotesEfso/send`, job);
export const deleteMotivation = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteMotivationalQuotesEfso/${project}`);

export const getPost = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getPostEfso/select/`);
export const updatePost = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updatePostEfso/${user.ID}`, user);
export const addPost = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addPostEfso/send`, job);
export const deletePost = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deletePostEfso/${project}`);

export const getAbout = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getAboutEfso/select/`);
export const updateAbout = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateAboutEfso/${user.ID}`, user);

export const updateContact = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateContactPort/${user.ID}`, user);

export const getLimit = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getTeacherEfso/select/`);
export const addLimit = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addTeacherEfso/send`, job);
export const deleteLimit = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteTeacherEfso/${project}`);
export const updateLimit = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateTeacherEfso/${user.ID}`, user);

export const updateProductSubCategory = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateQuestionSubCategoryEfso/${user.ID}`, user);
export const getSubCategory = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getQuestionSubCategoryEfso/select/`);
export const deleteProductSubCategory = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteQuestionSubCategoryEfso/${project}`);
export const addProductSubCategory = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addQuestionSubCategoryEfso/send`, job);


export const getAvailable = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getPdrCategoryEfso/select/`);
export const updateAvailable = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updatePdrCategoryEfso/${user.ID}`, user);
export const addAvailable = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addPdrCategoryEfso/send`, job);
export const deleteAvailable = project =>
del(`https://sihaadmin.stechomeyazilim.info:8182/deletePdrCategoryEfso/${project}`);



export const getProductSubSubCategory = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getLessonSubjectEfso/select/`);
export const deleteProductSubSubCategory = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteLessonSubjectEfso/${project}`);
export const updateSubSubProductCategory = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateLessonSubjectEfso/${user.ID}`, user);
export const addProductSubSubCategory = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addProductLessonSubjectEfso/send`, job);



export const addBrand = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addTestResultEfso/send`, job);
export const getBrand = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getTestResultEfso/select/`);
export const updateBrand = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateTestResultEfso/${user.ID}`, user);



export const getAdvert = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getAdvertTekneport/select/`);
export const updateAdvert = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateTekneportAdvert/${user.ID}`, user);
export const addAdvert= job => post(`https://sihaadmin.stechomeyazilim.info:8182/addAdvertTekneport/send`, job);
export const deleteAdvert= project =>
del(`https://sihaadmin.stechomeyazilim.info:8182/deleteAdvertPortsPort/${project}`);


export const getSalesPoint = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getSalesPointEfso/select/`);
export const updateSalesPoint = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateSalesPointEfso/${user.ID}`, user);
export const addSalesPoint= job => post(`https://sihaadmin.stechomeyazilim.info:8182/addSalesPointEfso/send`, job);
export const deleteSalesPoint= project =>
del(`https://sihaadmin.stechomeyazilim.info:8182/deleteSalesPointEfso/${project}`);



export const getCounter = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getCounterEfso/select/`);
export const updateCounter = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateCounterEfso/${user.ID}`, user);
export const addCounter= job => post(`https://sihaadmin.stechomeyazilim.info:8182/addCounterEfso/send`, job);
export const deleteCounter= project =>
del(`https://sihaadmin.stechomeyazilim.info:8182/deleteCounterEfso/${project}`);


export const getSong= () => get(`https://sihaadmin.stechomeyazilim.info:8182/getSongEfso/select/`);


export const getReservation = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getLessonEfso/select/`);
export const getReservationUsers = user => get(`https://sihaadmin.stechomeyazilim.info:8182/getLessonEfso/select/${user}`);

export const updateReservation = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateLessonEfso/${user.ID}`, user);
export const addReservation = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addLessonEfso/send`, job);
export const deleteReservation = project =>
del(`https://sihaadmin.stechomeyazilim.info:8182/deleteLessonEfso/${project}`);

export const getOffer = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getOfferPort/select/`);
export const updateOffer = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateSliderPort/${user.ID}`, user);
export const addOffer = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addSliderPort/send`, job);
export const deleteOffer = project =>
del(`https://sihaadmin.stechomeyazilim.info:8182/deleteSSSPort/${project}`);



export const getSSS = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getSSSEfso/select/`);
export const addSSS= job => post(`https://sihaadmin.stechomeyazilim.info:8182/addSSSEfso/send`, job);
export const deleteSSS = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteSSSEfso/${project}`);
export const updateSSS = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateSSSEfso/${user.ID}`, user);



export const getUsers = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getUsersEfso/select/`);
export const addNewUser = user => post(`https://sihaadmin.stechomeyazilim.info:8182/addUsersEfso/send`, user);
export const deleteUser = user => del(`https://sihaadmin.stechomeyazilim.info:8182/deleteUsersEfso/${user}`);
export const updateUser = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateUsersEfso/${user.ID}`, user);

export const getProjectsNew = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getEducationNefes/select/`);
export const getEducation = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getEducationNefes/select/`);
export const getWorkShop = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getSUBDOMAINMega/select/`);

export const getCourseSchedulesMega = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getCourseSchedulesMega/select/`);

export const getWorkshopSessionMega = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getWorkshopSessionMega/select/`);


export const getEducationSubUserItem = data => get(`https://sihaadmin.stechomeyazilim.info:8182/getPracticeNotificationEfso/select/${data}`);

export const getTeacherUserMega = data => get(`https://sihaadmin.stechomeyazilim.info:8182/getNotificationUserEfso/select/${data}`,);

export const getTeacherMega = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getNotificationEfso/select/`);

export const getEventMega = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getEventMega/select/`);

export const getDynamicFormMega = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getUsersComplateQuestionPdrEfso/select/`);



export const getNews = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getNewsMega/select/`);

export const getMegaMega = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getMegaMega/select/`);

export const getMailsLists = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getContactMessageMega/select/`);

// add user

const postFakeLogin = data => get(`https://sihaadmin.stechomeyazilim.info:8182/getUsersLoginUserEfso/select/${data.email}/${data.password}`,);

export const getEvents = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getEventMega/select/`);
export const getCategories = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getEventCategoryMega/select/`);
// update user

export const updateSubDomain = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchSUBDOMAINMega/${user.ID}`, user);

export const updateCourseSchedules = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchCourseSchedulesMega/${user.ID}`, user);

export const updateWorkshopSession = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchWorkShopSessionMega/${user.ID}`, user);

export const updateTeacher = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchUserMega/${user.ID}`, user);

export const getSlider = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getAnnEfso/select/`);
export const getSliders = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getAnnEfso/select/`);
export const updateSlider = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateAnnEfso/${user.ID}`, user);
export const addSlider = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addAnnEfso/send`, job);
export const deleteSlider = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteAnnEfso/${project}`);
  


export const getProducts1 = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getQuestionSubCategorySubEfso/select/`);
export const updateProduct = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateQuestionSubCategorySubEfso/${user.ID}`, user);
export const addProduct = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addQuestionSubCategorySubEfso/send`, job);
export const deleteProduct = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteQuestionSubCategorySubEfso/${project}`);
  



export const updateProject = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchUpdateProject/${user.ID}`, user);

export const updateNews = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchNews/${user.ID}`, user);


export const addUser = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addUsersDemir/send`, job);


export const addProject = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addProject/send`, job);

export const addNews = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addNews/send`, job);

export const addMega = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addMega/send`, job);

export const addCourseMega = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addCourseMega/send`, job);

export const updateSubdomain = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchSUBDOMAINMega/${user.ID}`, user);



export const updateAuth = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchAuthMega/${user.ID}`, user);

export const updateMega = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchMegaMega/${user.ID}`, user);

export const updateGallery = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchGalleryMega/${user.ID}`, user);

export const updateEvent = event => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchEventMega/${event.ID}`, event);

export const addAppSetting = job => post(`https://sihaadmin.stechomeyazilim.info:8182/postWSMega/send`, job);

export const addWorkshop2 = job => post(`https://sihaadmin.stechomeyazilim.info:8182/postWorkshopMega/send`, job);



// delete user

export const getContact = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getContactPort/select/`);
export const getAuthority = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getUsersComplateQuestionEfso/select/`);
export const getWorkshop2 = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getWorkshopMega/select/`);

export const updateWorkShop2 = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchWorkshop2Mega/${user.ID}`, user);

export const getGallery = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getGalleryMega/select/`);

export const addNewEvent = event => post(`https://sihaadmin.stechomeyazilim.info:8182/postEventMega/send/`, event);

export const getChats = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getContactMessageMega/select/`);

export const getMessages = (roomId = "") => get(`https://sihaadmin.stechomeyazilim.info:8182/getContactMessageDetailMega/select/${roomId}`);

export const addNewSubdomain = event => post(`https://sihaadmin.stechomeyazilim.info:8182/postSubdomainMega/send/`, event);
export const getReferances = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getReferancesMega/select/`);
// add jobs
export const addNewJobList = job => post(url.ADD_NEW_JOB_LIST, job);
// update jobs
export const updateJobList = job => put(url.UPDATE_JOB_LIST, job);
// delete jobs
export const deleteJobList = job => del(url.DELETE_JOB_LIST, { headers: { job } });
// Delete Apply Jobs
export const deleteApplyJob = data => del(url.DELETE_APPLY_JOB, { headers: { data } });
/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);

export const addReferances = event => post(`https://sihaadmin.stechomeyazilim.info:8182/postReferancesMega/send/`, event);

export const updateReferances = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchReferancesMega/${user.ID}`, user);

export const getSubProject = (ID = "") => get(`https://sihaadmin.stechomeyazilim.info:8182/getProjectSubItem/select/${ID}`);

export const getTeacherWorkshop = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getTeacherWorkshopMega/select/`);

export const addLessonsTime = event => post(`https://sihaadmin.stechomeyazilim.info:8182/postLessonsTimeMega/send/`, event);

export const updateLessonsTime = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchTeacherWorkshopMega/${user.ID}`, user);

export const getClassAttendance = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getClassAttendanceMega/select/`);

export const getClassesMega = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getClassesMega/select/`);

export const updateDynamicForm = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/patchDynanicFormMega/${user.ID}`, user);

export const addDynamicForm = job => post(`https://sihaadmin.stechomeyazilim.info:8182/postDynamicFormMega/send`, job);

export const getDynamicFormExtraMega = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getUsersComplateQuestionPdrEfso/select/`);

export const getDynamicFormExtraExtraMega = (ID = "") => get(`https://sihaadmin.stechomeyazilim.info:8182/getDynamicFormExtraExtraMega/select/${ID}`);


export const addGallerry = job => post(`https://sihaadmin.stechomeyazilim.info:8182/postGalleryMega/send`, job);

export const addMultipleGalleryForm = job => post(`https://sihaadmin.stechomeyazilim.info:8182/postGalleryMultipleMega/send`, job);


export const deleteDynamicFormExtraExtra = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteDynamicExtraExtraFormMega/${project}`);

export const deleteDynamicForm = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteDynamicFormMega/${project}`);

export const deleteDynamicFormExtra = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteDynamicFormExtraMega/${project}`);

export const deleteCourseSchedules = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteCourseSchedulesMega/${project}`);

export const deleteGallery = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteGalleryMega/${project}`);

export const deleteReferances = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteReferancesMega/${project}`);

export const deletePopup = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteLevelEfso/${project}`);
export const addPopup = job => post(`https://sihaadmin.stechomeyazilim.info:8182/addLevelEfso/send`, job);
export const updatePopup = user => patch(`https://sihaadmin.stechomeyazilim.info:8182/updateLevelEfso/${user.ID}`, user);
export const getPopup = () => get(`https://sihaadmin.stechomeyazilim.info:8182/getLevelEfso/select/`);



  

export const deleteNews = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteNewsMega/${project}`);

export const deleteMega = project =>
  del(`https://sihaadmin.stechomeyazilim.info:8182/deleteMega/${project}`);

export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get maillist
//update mail
export const updateMail = mail => put(url.UPDATE_MAIL, mail);

// get folderlist
export const selectFolders = () => get(url.SELECT_FOLDER);

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const walletBalanceData = month =>
  get(`${url.GET_WALLET_DATA}/${month}`, { params: { month } });

export const getStatisticData = duration =>
  get(`${url.GET_STATISTICS_DATA}/${duration}`, { params: { duration } });

export const visitorData = duration =>
  get(`${url.GET_VISITOR_DATA}/${duration}`, { params: { duration } });

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS);

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  });
};
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  });
};

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  });
};

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
};