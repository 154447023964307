import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import withRouter from "components/Common/withRouter";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import {
  getSSS as ongetSSS,
} from "store/contacts/actions";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FAQItem = ({ title, desc }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const faqItemStyle = {
    backgroundColor: isOpen ? '#e9ecef' : '#f9f9f9',
    border: '1px solid #ddd',
    borderRadius: '5px',
    marginBottom: '10px',
    padding: '10px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const faqTitleStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const faqDescStyle = {
    marginTop: '10px',
    padding: '10px',
    borderTop: '1px solid #ddd',
  };

  return (
    <div style={faqItemStyle} onClick={toggleOpen}>
      <div style={faqTitleStyle}>
        <h5>{title}</h5>
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      {isOpen && <div style={faqDescStyle}>{desc}</div>}
    </div>
  );
};

const ContactsList = props => {
  const dispatch = useDispatch();
  const [contact, setContact] = useState();

  const { users } = useSelector(state => ({
    users: state.contacts.sss,
  }));

  const [isEdit, setIsEdit] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    if (users && !users.length) {
      dispatch(ongetSSS());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
  }, [users]);

  const toggleDescription = index => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Yönetim" breadcrumbItem="SSS Listesi" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {users.map((item, index) => (
                  <FAQItem
                    key={index}
                    title={item.SSS_Title}
                    desc={item.SSS_Desc}
                  />
                ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
