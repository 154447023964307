import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { getTeacherMega as onGetTeacher } from "store/contacts/actions";
import { isEmpty } from "lodash";
import moment from "moment";
import "moment/locale/tr"; // Türkçe dil dosyasını import et

const ContactsList = (props) => {
  // meta title
  document.title = "Bildirim Listesi | Siha B2B PANELI";

  const dispatch = useDispatch();
  const { users } = useSelector(state => ({
    users: state.contacts.teacher,
  }));

  const [contact, setContact] = useState();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (users && !users.length) {

      let authUser = JSON.parse(localStorage.getItem("authUser"));

      if (authUser.Users_Type_ID == 2) {
        dispatch(onGetTeacher(authUser.ID));
        setIsEdit(false);
      }else{
        dispatch(onGetTeacher());
        setIsEdit(false);
      }

    
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  moment.locale('tr'); // Türkçe dil ayarını yap
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Kullanıcı" breadcrumbItem="Bildirim Listesi" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {users.map((item, index) => (
                    <div key={index} className={`notification-card ${item.Notification_Seen ? 'seen' : 'unseen'}`}>
                      <div className="notification-content">
                        <p className="notification-text">{item.Notification_Text}</p>
                        <p className="notification-desc">{item.Users.Users_NameSurname}</p>

                        <span className="notification-date">
                          {moment(item.Created_DateTime).format('DD/MM/YYYY, HH:mm')}
                        </span>
                      </div>
                      <div className={`status-indicator ${item.Notification_Seen ? 'seen-indicator' : 'unseen-indicator'}`} />
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactsList;
