import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/logo-light.png"
import Logo from "../../assets/images/logo-dark.png"

const WelcomeComp = () => {
  let getData = localStorage.getItem("authUser");

  return (
    <React.Fragment>
    <Card className="overflow-hidden">
      <div className="bg-primary bg-soft">
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h5 className="text-primary">Hoşgeldiniz !</h5>
              <p>Admin Paneli</p>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={profileImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <Row>
          <Col sm="4">
            <div className="avatar-md profile-user-wid mb-4">
              <img
                src={Logo}
                alt=""
                className="img-thumbnail rounded-circle"
              />
            </div>
            <h5 className="font-size-15 text-truncate">{JSON.parse(getData).Users_NameSurname}</h5>
            <p className="text-muted mb-0 text-truncate">{JSON.parse(getData).Users_TelephoneNumber}</p>
          </Col>

        </Row>

     

      </CardBody>
    </Card>
  </React.Fragment>
  )
}
export default WelcomeComp
